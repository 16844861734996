@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.heartImageWrapper {
  position: relative;
}

.listingLike {
  position: absolute;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.heartImageWrapper .heartIcon {
  height: 75px;
  width: 75px;
  background-position: left;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heartImageWrapper .listingLike {
  background: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.5rem;
  right: 0.5rem;
  transform: scale(0.8);
  transform-origin: top right;
  z-index: 1;
}

.favouriteIcon {
  width: 40px;
  object-fit: contain;
}

@keyframes animate {

  to {
    background-position: right;
  }

}

.disabled {
  cursor: not-allowed !important;
}

.deliveryIconWrapper {
  position: relative;
}

.deliveryIconWrapper .deliveryIcon {
  height: 48px;
  width: 46px;
  background: url('../../assets/Shipping badge.svg');
  cursor: pointer;
  position: absolute;
  transform: scale(0.7);
  transform-origin: top left;
  z-index: 1;
  left: 0.5rem;
  top: 0.5rem;
  border-radius: 50%;

  transition:
    width 0.5s,
    border-radius 0.5s;

  &:hover {
    width: 153px;
    border-radius: 0;
  }
}

.customListing{
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
}
