.root {
}

.input {
}

.textarea {
}

.inputSuccess {
}

.inputError {
  border: var(--borderErrorField);
}

.subdescription {
  font-size: 12px;
  margin-top: -11px;
  margin-bottom: 11px;
  display: block;
  color: var(--colorGrey300);
  font-size: 11px;
}
