.root {
}

.selectError {
  border: var(--borderErrorField);
}

.flexContent{
  display: flex;
  gap: 8px;
  align-items: center;
}

