/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  bottom: 1px;
  padding-right: 8px;
}

.additionalPadding{
  padding-bottom: 10px;

}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  max-width: calc(100vw - 125px);
  background-color: white;
  color: black;
  text-align: center;
  padding: 20px;
  border-radius: 6px;
  position: absolute;
  z-index: 2;
  left: 20px;
  font-size: 12px;
  text-transform: initial;
  box-shadow: var(--boxShadowButton);
  text-align: left;
  opacity: 0; /* For smooth transition */
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  pointer-events: none; /* Prevent interaction when not visible */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;

  & * {
    color: black;
  }
}

.tooltipTitle {
  font-size: 16px;
  font-weight: bold;
}

.tooltipSub {
  font-weight: normal;
  line-height: 22px;
}

.tooltipSubBold {
  /* padding: 8px 0px; */
  font-weight: bold !important;
  margin-top: 0.5rem;
  /* line-height: normal; */
}

.tooltipButton {
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
  display: flex;
  gap: 4px;
  align-items: center;
}

.tooltipButtonTitle {
  & * {
    color: var(--marketplaceColor) !important;
  }
}

.tooltipPadding {
  padding-top: 12px;
}

.paddingTop{
  padding-top: 8px;
}
